import React from 'react';
import * as Styles from '../styles';

import Collective_HealthIcon from 'images/Saludcolectivo.png';
import CompensationIcon from 'images/Money.png';
import Sports_FederationIcon from 'images/Desporto.png';
import CollectiveLifeIcon from 'images/Vidacolectivo.png';
import GroupAccidentIcon from 'images/Accidentescolectivo.png';
import LiabilityIcon from 'images/Responsibilidad.png';
import LegalDefenseIcon from 'images/Defensajuridica.png';

import { useTranslation } from 'gatsby-plugin-react-i18next';

const CARD_DESCRIPTION_ASOCIACIONES = [
  {
    icon: Collective_HealthIcon,
    description:
      'homepage.services.associations_groups_and_companies.collective_health',
  },
  {
    icon: CompensationIcon,
    description:
      'homepage.services.associations_groups_and_companies.flexible_compensation_plans',
  },
  {
    icon: Sports_FederationIcon,
    description:
      'homepage.services.associations_groups_and_companies.sports_federations_insurance',
  },
  {
    icon: CollectiveLifeIcon,
    description:
      'homepage.services.associations_groups_and_companies.collective_life',
  },
  {
    icon: GroupAccidentIcon,
    description:
      'homepage.services.associations_groups_and_companies.group_accidents',
  },
  {
    icon: LiabilityIcon,
    description:
      'homepage.services.associations_groups_and_companies.professional_civil_liability',
  },
  {
    icon: LegalDefenseIcon,
    description:
      'homepage.services.associations_groups_and_companies.legal_defense',
  },
];

interface CardsProps {
  element: {
    icon: any;
    description: string;
  };
}

const Card = ({ element }: CardsProps) => {
  const { icon, description } = element;

  const { t } = useTranslation();

  return (
    <Styles.DescriptionsRight>
      <img src={icon} alt="" aria-hidden="true" />
      <Styles.TextCardItems> {t(description)} </Styles.TextCardItems>
    </Styles.DescriptionsRight>
  );
};

const CardItemsFirstButton = () => {
  return (
    <div>
      {CARD_DESCRIPTION_ASOCIACIONES.map((item) => (
        <Card element={item} key={item.description} />
      ))}
    </div>
  );
};

export default CardItemsFirstButton;
