import React from 'react';
import * as Styles from '../styles';

import SickLeaveIcon from 'images/Baja.png';
import RiskTradeIcon from 'images/Multiriesgo.png';
import LossOfProfitIcon from 'images/Lucrocesante.png';
import ContigenciesIcon from 'images/Contigencias.png';
import Cyber_RiskIcon from 'images/DO.png';
import TravelInsuranceIcon from 'images/Viajesnegocios.png';

import { useTranslation } from 'gatsby-plugin-react-i18next';

const CARD_DESCRIPTION_AUTONOMOS = [
  {
    icon: SickLeaveIcon,
    description: 'homepage.services.autonomous_insurance.sick_leave',
  },
  {
    icon: RiskTradeIcon,
    description: 'homepage.services.autonomous_insurance.multi-risk_trade',
  },
  {
    icon: LossOfProfitIcon,
    description: 'homepage.services.autonomous_insurance.loss_of_profit',
  },
  {
    icon: ContigenciesIcon,
    description: 'homepage.services.autonomous_insurance.contigencies',
  },
  {
    icon: Cyber_RiskIcon,
    description: 'homepage.services.autonomous_insurance.cyber_risk',
  },
  {
    icon: TravelInsuranceIcon,
    description:
      'homepage.services.autonomous_insurance.business_travel_insurance',
  },
];

interface CardsProps {
  element: {
    icon: any;
    description: string;
  };
}

const Card = ({ element }: CardsProps) => {
  const { icon, description } = element;

  const { t } = useTranslation();

  return (
    <>
      <Styles.DescriptionsRight>
        <img src={icon} alt="" aria-hidden="true" />
        <Styles.TextCardItems> {t(description)} </Styles.TextCardItems>
      </Styles.DescriptionsRight>
    </>
  );
};

const CardItemsSecondButton = () => {
  return (
    <div>
      {CARD_DESCRIPTION_AUTONOMOS.map((item) => (
        <Card element={item} key={item.description} />
      ))}
    </div>
  );
};

export default CardItemsSecondButton;
