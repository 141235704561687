import React from 'react';
import * as Styles from '../styles';

import DamagesIcon from 'images/Danos.png';
import LiabilityIcon from 'images/Responsibilidad.png';
import BreakdownIcon from 'images/Averiamaquinaria.png';
import LossOfBenefitsIcon from 'images/Perdidadebeneficios.png';
import ElectricalDamageIcon from 'images/Danoselectricos.png';
import Seguroconvenio from 'images/Seguroconvenio.png';
import LossOfProfitIcon from 'images/Lucrocesante.png';
import insurance_contingencies from 'images/Contigencias.png';
import InsuranceDO from 'images/Viajesnegocios.png';
import CyberRysksIcon from 'images/Ciberriesgos.png';

import { useTranslation } from 'gatsby-plugin-react-i18next';

const CARD_DESCRIPTION_SEGUROS_EMPRESAS = [
  {
    icon: DamagesIcon,
    description: 'homepage.services.business_insurance.damages',
  },
  {
    icon: LiabilityIcon,
    description: 'homepage.services.business_insurance.liability',
  },
  {
    icon: LossOfBenefitsIcon,
    description: 'homepage.services.business_insurance.loss_of_benefits',
  },
  {
    icon: BreakdownIcon,
    description: 'homepage.services.business_insurance.machinery_breakdown',
  },
  {
    icon: ElectricalDamageIcon,
    description: 'homepage.services.business_insurance.electrical_damage',
  },
  {
    icon: Seguroconvenio,
    description: 'homepage.services.business_insurance.insurance_agreement',
  },
  {
    icon: LossOfProfitIcon,
    description: 'homepage.services.business_insurance.loss_of_profit',
  },
  {
    icon: insurance_contingencies,
    description: 'homepage.services.business_insurance.contingencies',
  },
  {
    icon: CyberRysksIcon,
    description: 'homepage.services.business_insurance.cyber-risks',
  },
  {
    icon: InsuranceDO,
    description: 'homepage.services.business_insurance.d&o',
  },
];

interface CardsProps {
  element: {
    icon: any;
    description: string;
  };
}

const Card = ({ element }: CardsProps) => {
  const { icon, description } = element;

  const { t } = useTranslation();

  return (
    <Styles.DescriptionsRight>
      <img src={icon} alt="" aria-hidden="true" />
      <Styles.TextCardItems> {t(description)} </Styles.TextCardItems>
    </Styles.DescriptionsRight>
  );
};

const CardItemsThirdButton = () => {
  return (
    <div>
      {CARD_DESCRIPTION_SEGUROS_EMPRESAS.map((item) => (
        <Card element={item} key={item.description} />
      ))}
    </div>
  );
};

export default CardItemsThirdButton;
