import React from 'react';
import * as Styles from '../styles';

import ItemsFirstButton from './CardItemsFirstButton';
import ItemsSecondButton from './CardItemsSecondButton';
import ItemsThirdButton from './CardItemsThirdButton';
import ItemsFourthButton from './CardItemsFourthButton';

import Button from 'components/Button';

import { useTranslation } from 'gatsby-plugin-react-i18next';

const DescriptionOfInsurance = {
  socialcare: {
    title: 'homepage.services.personal_insurance.title',
    description: 'homepage.services.personal_insurance.subtitle',
    text: 'homepage.services.personal_insurance.description',
  },
  piechart: {
    title: 'homepage.services.autonomous_insurance.title',
    description: 'homepage.services.autonomous_insurance.subtitle',
    text: 'homepage.services.personal_insurance.description',
  },
  suitcase: {
    title: 'homepage.services.business_insurance.title',
    description: 'homepage.services.business_insurance.subtitle',
    text: 'homepage.services.business_insurance.description',
  },
  groupchat: {
    title: 'homepage.services.associations_groups_and_companies.title',
    description: 'homepage.services.associations_groups_and_companies.subtitle',
    text: 'homepage.services.associations_groups_and_companies.description',
  },
};

interface DescriptionProps {
  element: {
    title: string;
    description: string;
    text: string;
  };
  selectedService: any;
}

const insuranceDescriptionCards: any = {
  socialcare: <ItemsFirstButton />,
  piechart: <ItemsSecondButton />,
  suitcase: <ItemsThirdButton />,
  groupchat: <ItemsFourthButton />,
};

const DescriptionContent = ({ element, selectedService }: DescriptionProps) => {
  const { title, description, text } = element;

  const { t } = useTranslation();

  return (
    <Styles.BoxDescriptionCards>
      <Styles.ServiceDescription>
        <h5>{t(title)}</h5>
        <p className="description">{t(description)}</p>
        <p className="text">{t(text)}</p>
        <Styles.ButtonStylingWeb>
          <Button href="/about">{t('home.contact_us.cta_button')}</Button>
        </Styles.ButtonStylingWeb>
      </Styles.ServiceDescription>
      <Styles.DescriptionWrapper>
        {insuranceDescriptionCards[selectedService]}
        <Styles.ButtonStylingMob>
          <Button href="/about">{t('home.contact_us.cta_button')}</Button>
        </Styles.ButtonStylingMob>
      </Styles.DescriptionWrapper>
    </Styles.BoxDescriptionCards>
  );
};

const ServicesDescription = ({ selectedService }: DescriptionProps) => {
  const item = DescriptionOfInsurance[selectedService];

  return (
    <>
      <DescriptionContent
        element={item}
        key={item.title}
        selectedService={selectedService}
      />
    </>
  );
};

export default ServicesDescription;
