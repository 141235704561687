import React, { useState } from 'react'
import type { HeadFC } from 'gatsby';
import ServicesCards from './components/ServicesCards';
import PageHero from 'components/PageHero';

const Services = () => {
  const [selectedService, setSelectedService] = useState('socialcare');


  return (
    <>
      <PageHero />
      <ServicesCards onSelectedService={setSelectedService} selectedService={selectedService} />
    </>
  );
};

export default Services;

export const Head: HeadFC = () => <title>Services</title>;
