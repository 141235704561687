import React from 'react';
import * as Styles from '../styles';

import LifeIcon from 'images/Life.png';
import StudiesIcon from 'images/Estudios.png';
import AccidentsIcon from 'images/Accidentes.png';
import DeathIcon from 'images/Decesos.png';
import HealthIcon from 'images/Salud.png';
import LegalDefenseIcon from 'images/Defensajuridica.png';
import PrivateTravelsIcon from 'images/Viajesprivados.png';

import { useTranslation } from 'gatsby-plugin-react-i18next';

const CARD_DESCRIPTION_PERSONALES = [
  {
    icon: LifeIcon,
    description: 'homepage.services.personal_insurance.life',
  },
  {
    icon: StudiesIcon,
    description: 'homepage.services.personal_insurance.studies',
  },
  {
    icon: AccidentsIcon,
    description: 'homepage.services.personal_insurance.accidents',
  },
  {
    icon: DeathIcon,
    description: 'homepage.services.personal_insurance.death',
  },
  {
    icon: HealthIcon,
    description: 'homepage.services.personal_insurance.health',
  },
  {
    icon: LegalDefenseIcon,
    description: 'homepage.services.personal_insurance.legal_defense',
  },
  {
    icon: PrivateTravelsIcon,
    description: 'homepage.services.personal_insurance.private_travels',
  },
];

interface CardsProps {
  element: {
    icon: any;
    description: string;
  };
}

const Card = ({ element }: CardsProps) => {
  const { icon, description } = element;

  const { t } = useTranslation();

  return (
    <Styles.DescriptionsRight>
      <img src={icon} alt="" aria-hidden="true" />
      <Styles.TextCardItems> {t(description)} </Styles.TextCardItems>
    </Styles.DescriptionsRight>
  );
};

const CardItemsFirstButton = () => {
  return (
    <div>
      {CARD_DESCRIPTION_PERSONALES.map((item) => (
        <Card element={item} key={item.description} />
      ))}
    </div>
  );
};

export default CardItemsFirstButton;
