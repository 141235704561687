import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from 'theme';

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 80px 180px;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 60px 0;
    align-items: start;
  }
`;

export const ContainerTop = styled.div`
  display: flex;
  justify-content: center;
  gap: 26px;

  @media (max-width: ${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 156px);
  }
`;

export const CardButton = styled.button<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${rem(238)};
  height: ${rem(204)};
  border-radius: ${rem(10)};
  cursor: pointer;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.brandPalette.primary : theme.neutralPalette.white};
  gap: 16px;

  img {
    width: ${rem(80)};
  }
  .white-icon {
    width: ${rem(100)};
  }
  p {
    font-size: ${rem(18)};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${({ isSelected, theme }) =>
      isSelected ? theme.brandPalette.light : theme.brandPalette.primary};
  }

  @media (max-width: ${breakpoints.tablet}) {
    align-items: center;
    width: 156px;
    height: 132px;
    left: 24px;
    top: 250px;

    img {
      width: ${rem(39.9)};
      height: ${rem(39.9)};
    }
    .white-icon {
      width: ${rem(40.6)};
      width: ${rem(40.6)};
    }
    p {
      font-size: ${rem(12)};
      line-height: ${rem(18)};
    }
  }
`;

export const ServiceDescription = styled.div`
  color: ${(props) => props.theme.brandPalette.dark};

  h5 {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(28)};
    line-height: ${rem(40)};
  }

  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    color: ${(props) => props.theme.brandPalette.dark};
    opacity: 0.8;
    padding-right: 100px;
    padding-top: 24px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: ${rem(327)};
  }
`;

export const ButtonStylingWeb = styled.div`
  margin-top: ${rem(32)};
  border-radius: ${rem(8)};
  margin-top: 32px;
  margin-top: ${rem(32)};
  border-radius: ${rem(8)};

  @media (max-width: ${breakpoints.tablet}) {
    display: none;

    button {
      width: 100%;
      margin-bottom: 40px;
    }
  }
`;
export const ButtonStylingMob = styled.div`
  display: none;

  @media (max-width: ${breakpoints.tablet}) {
    display: block;
    margin-top: ${rem(32)};
    border-radius: ${rem(8)};
    button {
      width: 100%;
      margin-bottom: 40px;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  color: ${(props) => props.theme.brandPalette.dark};

  h5 {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    font-size: ${rem(30)};
    line-height: ${rem(40)};
    padding: 13px 0;
  }
`;

export const TextCardItems = styled.p`
  opacity: 0.8;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  color: ${(props) => props.theme.brandPalette.dark};
  padding-left: ${rem(16)};
`;

export const DescriptionsRight = styled.div`
  display: flex;
  flex-direction: row;
  width: ${rem(441)};
  height: ${rem(64)};
  align-items: center;
  background-color: ${(props) => props.theme.brandPalette.light};
  margin-bottom: ${rem(8)};
  border-radius: ${rem(8)};

  @media (max-width: ${breakpoints.tablet}) {
    width: ${rem(327)};
    height: ${rem(64)};
  }
`;

export const BoxDescriptionCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: start;
  align-content: space-around;
  margin-top: ${rem(80)};
  max-width: 1440px;

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;

    h5 {
      font-weight: ${(props) => props.theme.fontWeights.semibold};
      font-size: 24px;
      line-height: 27px;
      margin-bottom: 16px;
    }
    .description {
      font-weight: ${(props) => props.theme.fontWeights.regular};
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 16px;
    }
    .text {
      font-weight: ${(props) => props.theme.fontWeights.regular};
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 40px;
    }
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-bottom: ${rem(20)};

  @media (max-width: ${breakpoints.tablet}) {
    align-items: start;
    padding-bottom: ${rem(4)};
    margin-top: -28px;

    button {
      width: 100%;
      margin-bottom: 40px;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  @media (max-width: ${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 156px);
    padding: 0 10px;
    gap: 10px;
  }
`;

export const Header = styled.h2`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fontWeights.light};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.neutralPalette.neutral6};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(32)};
    margin-top: ${rem(30)};
  }
`;

export const Subtitle = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(32)};
  line-height: ${rem(20)};
  margin-top: 20px;
  color: ${(props) => props.theme.brandPalette.dark};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(28)};
    line-height: ${rem(32)};
    margin-top: 5px;
    margin-bottom: ${rem(10)};
  }
`;
