import React from 'react';
import * as Styles from '../styles';

import ServicesDescription from './ServicesDescription';
import Box from 'components/Box';

import Groupchat from 'images/Groupchat.png';
import Piechart from 'images/Piechart.png';
import Socialcare from 'images/Socialcare.png';
import Suitcase from 'images/suitcase.png';
import Personaleswhite from 'images/Personaleswhite.png';
import Autonomoswhite from 'images/Autonomoswhite.png';
import Suitcasewhite from 'images/Suitcasewhite.png';
import Colectivowhite from 'images/Colectivowhite.png';

import { useTranslation } from 'gatsby-plugin-react-i18next';

const BUTTOM_ITEMS = [
  {
    icon: Socialcare,
    whiteIcon: Personaleswhite,
    text: 'homepage.services.product_list.personal_insurance',
    key: 'socialcare',
  },
  {
    icon: Piechart,
    whiteIcon: Autonomoswhite,
    text: 'homepage.services.product_list.autonomous_insurance',
    key: 'piechart',
  },
  {
    icon: Suitcase,
    whiteIcon: Suitcasewhite,
    text: 'homepage.services.product_list.business_insurance',
    key: 'suitcase',
  },
  {
    icon: Groupchat,
    whiteIcon: Colectivowhite,
    text: 'homepage.services.product_list.associations_collectives_and_businesses',
    key: 'groupchat',
  },
];

interface CardProps {
  element: {
    icon: any;
    whiteIcon: any;
    text: string;
    key: string;
  };
  isSelected: boolean;
  onClick: any;
}

const Card = ({ element, onClick, isSelected }: CardProps) => {
  const { text, icon, whiteIcon, key } = element;
  const { t } = useTranslation();

  const handleClick = () => {
    onClick(key);
  };

  return (
    <Styles.CardButton onClick={handleClick} isSelected={isSelected}>
      {isSelected ? (
        <img src={whiteIcon} className="white-icon" alt="" aria-hidden="true" />
      ) : (
        <img src={icon} alt="" aria-hidden="true" />
      )}
      <p>{t(text)}</p>
    </Styles.CardButton>
  );
};

const ServicesCards = ({ onSelectedService, selectedService }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Styles.Container>
          <Styles.Header>
            {t('homepage.services.product_list.title')}
          </Styles.Header>
          <Styles.Subtitle>
            {t('homepage.services.product_list.description')}
          </Styles.Subtitle>
        </Styles.Container>
      </Box>
      <Styles.Flex>
        {BUTTOM_ITEMS.map((item) => (
          <Card
            element={item}
            key={item.text}
            onClick={onSelectedService}
            isSelected={selectedService === item.key}
          />
        ))}
      </Styles.Flex>
      <Box>
        <Styles.Container>
          <ServicesDescription
            selectedService={selectedService}
            element={{
              title: '',
              description: '',
              text: '',
            }}
          />
        </Styles.Container>
      </Box>
    </>
  );
};

export default ServicesCards;
